<template>
  <div class="treatment">
    <img src="/img/logo.svg" alt="Logo Instituto Burmann & Bastos" class="logo">
    <transition name="page" mode="out-in">
      <div class="d-p">
        <h1>{{ loading ? 'Carregando...' : treatment.title }}</h1>

        <div class="treatment-content" v-if="!loading">
          <div class="text-center">
            <img :src="$mediaUrl + treatment.image" :alt="treatment.title">
          </div>
          <div class="desc" v-html="treatment.short_description"></div>
        </div>

        <div class="four-cols" v-if="treatments.length > 0">
          <router-link v-for="treatment in treatments" :key="treatment.id" :to="$route.path + '/' + treatment.url" class="step-link">
            <img :src="$mediaUrl + treatment.image" :alt="treatment.title">
            {{ treatment.title }}
          </router-link>
        </div>

        <div class="form-wrapper">
          <h4>Pré-agende sua consulta</h4>
          <form action="#" id="contact-form" v-on:submit="submitForm">
            <input type="text" name="name" placeholder="Seu nome" required>
            <input type="tel" name="phone" placeholder="Seu telefone" required v-mask="['(##) #####-####', '(##) ####-####']">
            <input type="email" name="email" placeholder="Seu email" required>
            <input type="text" name="time" placeholder="Turno de preferência" required>
            <input type="text" name="website" class="website-input" placeholder="Seu website">
            <button type="submit" id="submit-btn" class="btn btn-default">Enviar</button>
          </form>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { HTTP } from '../helpers/http';
import { mask } from 'vue-the-mask'

export default {
  name: 'Treatment',
  components: {},
  directives: { mask },
  data: () => {
    return {
      loading: true,
      treatment: {},
      treatments: []
    }
  },
  props: {
    title: String,
    url: String
  },
  async created() {

    HTTP.get('lista' + this.$route.path)
        .then(response => {
          this.loading = false;
          this.treatment = response.data.data;
          this.treatments = response.data.list;
        })
        .catch(err => {
          this.$snotify.error(err.response.data.message, 'Ooops');
        });
  }
}
</script>

<style scoped lang="scss">
  .treatment {
    padding-top: 25px;
    text-align: center;
    position: relative;

    .logo {
      max-width: 90%;
      width: 150px;
      height: auto;
      position: relative;
    }
  }
</style>
